import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import { IconButton, Snackbar, type SxProps, type Theme } from '@mui/material'
import Tooltip from '@mui/material/Tooltip'
import { type FC, useState } from 'react'

import { themeSpacingFromPixels } from '../../shared/lib/workWithThemeSpacing'

interface Props {
  content: string
  tooltipText?: string
  sx?: SxProps<Theme>
  buttonSx?: SxProps<Theme>
  icon?: JSX.Element

}

export const CopyToClipboardButton: FC<Props> = ({
  content,
  tooltipText,
  sx: sxOverwrite,
  buttonSx: buttonSx,
  icon,
}) => {
  const [open, setOpen] = useState(false)

  const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation()
    setOpen(true)
    navigator.clipboard.writeText(content).then(_ => {
    })
  }

  return (
    <>
      <Tooltip
        arrow
        placement="bottom"
        title={tooltipText ?? 'Copy to clipboard'}
      >
        <IconButton
          sx={{
            ml: 0.5,
            height: themeSpacingFromPixels(28),
            ...buttonSx,
          }}
          aria-label="copy to clipboard"
          onClick={(e) => {
            handleClick(e)
          }}
          edge="start"
        >
          {icon
            ? icon
            :
            <ContentCopyIcon sx={{
              pointerEvents: 'auto',
              ...sxOverwrite,
            }}
            />
          }
        </IconButton>
      </Tooltip>
      <Snackbar
        message="Copied to clipboard"
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        autoHideDuration={2000}
        onClose={() => {
          setOpen(false)
        }}
        open={open}
      />
    </>
  )
}

export default CopyToClipboardButton
