import {
  createSlice,
  type PayloadAction,
  type SerializedError, createAsyncThunk,
} from '@reduxjs/toolkit'

import unitsApi from '../../../shared/api/UnitsApi'
import { type RootState } from '../store'

export interface UnitsFilterState {
  status: 'idle' | 'loading' | 'failed'
  unitFilter?: string
  prevFilters: UnitFilter []
  unitFilters: UnitFilter []
  nextFilters: UnitFilter []
  variants?: UnitVariant[]
  searchTrigger: boolean
  filtersOpened: boolean
  error?: SerializedError
}

export interface UnitFilter {
  key: string
  value?: string
}

export interface UnitVariant {
  equipmentType?: string
  oemBrandName?: string
  oemSubType?: string
  process?: string
  productGroup?: string
  productKey?: string
  statusKd?: string
}

const initialState: UnitsFilterState = {
  status: 'idle',
  prevFilters: [],
  unitFilters: [],
  nextFilters: [],
  searchTrigger: false,
  filtersOpened: false,
}

export const getFilterVariants = createAsyncThunk(
  'units-variants/load',
  async (request: {
    filters: UnitFilter[],
  }) => {
    return await unitsApi.getVariants(
      request.filters,
    )
  },
)

export const unitsFilterSlice = createSlice({
  name: 'units-list-filters',
  initialState,
  reducers: {
    addUnitFilter: (state, action: PayloadAction<UnitFilter>) => {
      const all = [
        ...state.prevFilters,
        ...state.unitFilters,
        ...state.nextFilters,
      ]
      if (!all.some(f => f.key === action.payload.key)) {
        if (state.unitFilters.length < 20) {
          state.unitFilters.push(action.payload)
        } else {
          const shiftedCurrent = state.unitFilters.shift()
          if (shiftedCurrent) {
            state.prevFilters.push(shiftedCurrent)
          }
          state.unitFilters.push(action.payload)
        }
      }
    },
    removeUnitFilter: (state, action: PayloadAction<string>) => {
      const filter = state.unitFilters.find(f => f.key === action.payload)
      if (filter) {
        state.unitFilters = state.unitFilters.filter(f => f.key !== action.payload)
      }
      const poppedPrev = state.prevFilters.pop()
      if (poppedPrev) {
        state.unitFilters.unshift(poppedPrev)
      } else {
        const shiftedNext = state.nextFilters.shift()
        if (shiftedNext) {
          state.unitFilters.push(shiftedNext)
        }
      }
    },
    removeUnitFilters: (state) => {
      state.unitFilters = []
      state.prevFilters = []
      state.nextFilters = []
    },
    moveFilterNext: (state) => {
      const shiftedCurrent = state.unitFilters.shift()
      if (shiftedCurrent) {
        state.prevFilters.push(shiftedCurrent)
      }
      const shiftedNext = state.nextFilters.shift()
      if (shiftedNext) {
        state.unitFilters.push(shiftedNext)
      }
    },
    moveFilterPrev: (state) => {
      const poppedPrev = state.prevFilters.pop()
      if (poppedPrev) {
        state.unitFilters.unshift(poppedPrev)
      }
      const poppedCurrent = state.unitFilters.pop()
      if (poppedCurrent) {
        state.nextFilters.unshift(poppedCurrent)
      }
    },
    changeUnitFilter: (state, action: PayloadAction<UnitFilter>) => {
      const filter = state.unitFilters.find(f => f.key === action.payload.key)
      if (filter) {
        filter.value = action.payload.value
      }
    },
    setUnitFilter: (state, action: PayloadAction<string | undefined>) => {
      state.unitFilter = action.payload
    },
    clearAllFilters: (state) => {
      state.unitFilter = undefined
    },
    clearVariants: (state) => {
      state.variants = undefined
    },
    setSearchTrigger: (state, action: PayloadAction<boolean>) => {
      state.searchTrigger = action.payload
    },
    setFiltersOpened: (state, action: PayloadAction<boolean>) => {
      state.filtersOpened = action.payload
    },
  },
  extraReducers: (build) => {
    build.addCase(getFilterVariants.pending, (state, action) => {
      state.status = 'loading'
    })

    build.addCase(getFilterVariants.fulfilled, (state, action) => {
      state.status = 'idle'
      state.variants = action.payload
    })

    build.addCase(getFilterVariants.rejected, (state, action) => {
      state.status = 'failed'
      state.error = action.error
    })
  },
})

export const {
  setUnitFilter,
  clearAllFilters,
  addUnitFilter,
  changeUnitFilter,
  removeUnitFilter,
  clearVariants,
  moveFilterNext,
  moveFilterPrev,
  setSearchTrigger,
  removeUnitFilters,
  setFiltersOpened,
} = unitsFilterSlice.actions

export const selectUnitsFilters = (state: RootState) =>
  state.units.list.filters

export const selectMfgFilter = (state: RootState) => {
  const all = [
    ...state.units.list.filters.unitFilters,
    ...state.units.list.filters.nextFilters,
    ...state.units.list.filters.prevFilters,
  ]
  return all.find(f => f.key === 'Mfg')
}

export const selectLogisticsDataFilter = (state: RootState) => {
  const all = [
    ...state.units.list.filters.unitFilters,
    ...state.units.list.filters.nextFilters,
    ...state.units.list.filters.prevFilters,
  ]
  return all.find(f => f.key === 'LogisticsData')
}

export const selectPgsFilter = (state: RootState) => {
  const all = [
    ...state.units.list.filters.unitFilters,
    ...state.units.list.filters.nextFilters,
    ...state.units.list.filters.prevFilters,
  ]
  return all.find(f => f.key === 'ProductGroups')
}

export default unitsFilterSlice.reducer
