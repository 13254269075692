import {
  createAsyncThunk,
  createSlice,
  type PayloadAction,
  type SerializedError,
} from '@reduxjs/toolkit'

import unitsApi from 'shared/api/UnitsApi'

import { type RootState } from '../store'

import { UnitFilter } from './unitsFiltersSlice'

const name = 'custom-download-dialog'

interface CustomDownloadDialogState {
  open: boolean
  blob?: string
  status: 'idle' | 'pending' | 'rejected'
  getTopLevel: boolean
  getAllLevels: boolean
  getOutline: boolean
  getInspection: boolean
  getDesign: boolean
  getModels: boolean
  error?: SerializedError
}

const initialState: CustomDownloadDialogState = {
  open: false,
  status: 'idle',
  getTopLevel: false,
  getAllLevels: false,
  getOutline: false,
  getInspection: false,
  getDesign: false,
  getModels: false,
  error: undefined,
}

export const downloadCustom = createAsyncThunk(
  `${name}/downloadCustom`,
  async (payload: {
    filter: string,
    onlyTop: boolean,
    includeAllLevels: boolean,
    categories: number[],
    filters?: UnitFilter[]
  }) => {
    return await unitsApi.downloadCustom(payload.filter, payload.onlyTop, payload.includeAllLevels, payload.categories, payload.filters)
      .then(async (res) => await res.blob())
      .then((blob) => blob && window.URL.createObjectURL(blob))
  },
)

const slice = createSlice({
  name,
  initialState,
  reducers: {
    openCustomDownload: (
      _) => {
      return {
        ...initialState,
        open: true,
      }
    },
    closeCustomDownload: (state) => {
      return {
        ...state,
        open: false,
      }
    },
    changeGetTopLevel: (state, action: PayloadAction<boolean>) => {
      state.getTopLevel = action.payload
    },
    changeGetAllLevels: (state, action: PayloadAction<boolean>) => {
      state.getAllLevels = action.payload
    },
    changeGetOutline: (state, action: PayloadAction<boolean>) => {
      state.getOutline = action.payload
    },
    changeGetInspection: (state, action: PayloadAction<boolean>) => {
      state.getInspection = action.payload
    },
    changeGetDesign: (state, action: PayloadAction<boolean>) => {
      state.getDesign = action.payload
    },
    changeGetModels: (state, action: PayloadAction<boolean>) => {
      state.getModels = action.payload
    },
    clearDrawingsBlob: (state) => {
      state.blob = undefined
    },
  },
  extraReducers: (build) => {
    build.addCase(downloadCustom.pending, (state) => {
      state.status = 'pending'
      state.error = undefined
    })

    build.addCase(downloadCustom.fulfilled, (state, action) => {
      state.status = 'idle'
      state.blob = action.payload
      state.open = false
    })

    build.addCase(downloadCustom.rejected, (state, action) => {
      state.status = 'rejected'
      state.error = action.error
    })
  },
})

export const {
  openCustomDownload,
  closeCustomDownload,
  changeGetTopLevel,
  changeGetAllLevels,
  changeGetOutline,
  changeGetInspection,
  changeGetDesign,
  changeGetModels,
  clearDrawingsBlob,

} = slice.actions
export const selectCustomDownloadDialogState = (state: RootState) => state.units.list.customDownloadDialog
export default slice.reducer
