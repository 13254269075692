import {
  type IPublicClientApplication,
  PublicClientApplication,
} from '@azure/msal-browser'

import {
  apiRequest,
  loginRequest,
} from 'app/config/authConfig'

import { REACT_APP_AZURE_CLIENT_ID, REACT_APP_AZURE_AUTHORITY } from 'shared/api/api'

class AuthService {

  private msalInstance: IPublicClientApplication | undefined

  constructor () {
  }

  async getInstance () {
    const msalConfig = {
      auth: {
        clientId: REACT_APP_AZURE_CLIENT_ID ?? '',
        authority: REACT_APP_AZURE_AUTHORITY ?? '',
        redirectUri: `${window.location.protocol}//${window.location.hostname}${
          window.location.port === '443'
            ? ''
            : ':' + window.location.port
        }`,
      },
      cache: {
        cacheLocation: 'sessionStorage', // This configures where your cache will be stored
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
      },
    }

    this.msalInstance = new PublicClientApplication(msalConfig)
    return this.msalInstance
  }

  async getClientToken () {
    if (this.msalInstance) {
      const accounts = this.msalInstance.getAllAccounts()
      const account = accounts[0]
      if (account) {
        const request = {
          ...loginRequest,
          account,
        }

        return await this.msalInstance.acquireTokenSilent(request)
      }
    }
    throw new Error('Failed to access')
  }

  async getIdentity () {
    if (this.msalInstance) {
      const accounts = this.msalInstance.getAllAccounts()
      const account = accounts[0]
      if (account) {
        const request = {
          ...apiRequest,
          account,
        }
        return await this.msalInstance.acquireTokenSilent(request)
      }
    }
    throw new Error('Failed to access')
  }
}

const authService = new AuthService()

export default authService
