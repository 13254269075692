import { UnitModel } from '../../app/store/units/createUnitDialogSlice'
import { ConsumptionCategoryDto } from '../model/contracts/ConsumptionCategoryDto'
import { ProductGroupDto } from '../model/contracts/ProductGroupDto'
import { UnitOfMeasureDto } from '../model/contracts/UnitOfMeasureDto'

import { API_GATEWAY_ADDRESS } from './api'
import authService from './AuthService'
import { handleErrors } from './errorHandler'
import { msalFetch } from './graph'

export interface CreateUnitRequest {
  equipmentId: number | null,
  productGroup: string | null,
  productKey: string | null,
  consumptionCategory: number | null,
  type: string | null,
  oemNumber: string | null,
  nameEn: string | null,
  nameRu: string | null,
  productionName: string | null,
  productLineNotesEn: string | null,
  productLineNotesRu: string | null,
  length: number | null,
  widthOrDiameter: number | null,
  height: number | null,
  weight: number | null,
  material: string | null,
  oemMaterial: string | null,
  unitOfMeasurement: string | null,
  comment: string | null,
  doNotShowEparts: boolean,
  notForSmNf: boolean,
  subOems: CreateSubOemRequest[] | null,
  oversized: boolean,
}

export interface CreateEtoUnitRequest {
  projectId: number | null,
  customer: string | null,
  productGroup: string | null,
  productKey: string | null,
  consumptionCategory: number | null,
  type: string | null,
  oemNumber: string | null,
  nameEn: string | null,
  nameRu: string | null,
  productionName: string | null,
  productLineNotesEn: string | null,
  productLineNotesRu: string | null,
  length: number | null,
  widthOrDiameter: number | null,
  height: number | null,
  weight: number | null,
  material: string | null,
  oemMaterial: string | null,
  unitOfMeasurement: string | null,
  comment: string | null,
  doNotShowEparts: boolean,
  notForSmNf: boolean,
  subOems: CreateSubOemRequest[] | null,
  oversized: boolean,
}

export interface CreateSubOemRequest {
  mfg: string | null,
  mfgOemNumber: string | null,
}

export class CreateUnitApi {
  async loadProductGroups () {
    const result = await authService.getIdentity()
    return await msalFetch()
      .get(`${API_GATEWAY_ADDRESS}/uc/productgroups`, result.accessToken)
      .then(handleErrors)
      .then<ProductGroupDto[]>(async (res) => await res.json())
  }

  async loadUnitsOfMeasure () {
    const result = await authService.getIdentity()
    return await msalFetch()
      .get(`${API_GATEWAY_ADDRESS}/uc/unitsofmeasure`, result.accessToken)
      .then(handleErrors)
      .then<UnitOfMeasureDto[]>(async (res) => await res.json())
  }

  async loadMfgs () {
    const result = await authService.getIdentity()
    return await msalFetch()
      .get(`${API_GATEWAY_ADDRESS}/uc/mfg`, result.accessToken)
      .then(handleErrors)
      .then<string[]>(async (res) => await res.json())
  }

  async loadConsumptionCategories () {
    const result = await authService.getIdentity()
    return await msalFetch()
      .get(`${API_GATEWAY_ADDRESS}/uc/consumptioncategories`, result.accessToken)
      .then(handleErrors)
      .then<ConsumptionCategoryDto[]>(async (res) => await res.json())
  }

  async submitUnit (unitModel: UnitModel) {
    const result = await authService.getIdentity()

    if (unitModel.type === 'unit') {
      const unitValidationRequest: CreateUnitRequest = {
        equipmentId: unitModel.equipmentModel?.id ?? null,
        productGroup: unitModel.productGroupModel?.code ?? null,
        productKey: unitModel.productKeyModel?.code ?? null,
        consumptionCategory: unitModel.consumptionCategory?.id ?? null,
        type: unitModel.oemType ?? null,
        oemNumber: unitModel.oemNumber ?? null,
        nameEn: unitModel.nameEn ?? null,
        nameRu: unitModel.nameRus ?? null,
        productionName: unitModel.productionName ?? null,
        productLineNotesEn: unitModel.plNotesEn ?? null,
        productLineNotesRu: unitModel.plNotesRus ?? null,
        length: Number(unitModel.length) ?? null,
        widthOrDiameter: Number(unitModel.widthOrDiameter) ?? null,
        height: Number(unitModel.height) ?? null,
        weight: Number(unitModel.weight) ?? null,
        material: unitModel.materialModel?.materialId ?? null,
        unitOfMeasurement: unitModel.unitsOfMeasure?.id ?? null,
        doNotShowEparts: unitModel.doNotShowEparts,
        notForSmNf: unitModel.notForSmNf,
        oemMaterial: unitModel.oemMaterial ?? null,
        comment: unitModel.commentForApproval ?? null,
        subOems: unitModel.subOems.length > 0
          ? unitModel.subOems.map((s) => {
            return {
              mfg: s.mfg ?? '',
              mfgOemNumber: s.mfgOemNumber ?? '',
            }
          })
          : null,
        oversized: unitModel.oversized ?? false,
      }

      await msalFetch()
        .post(`${API_GATEWAY_ADDRESS}/uc/unitcreaterequests`, result.accessToken, unitValidationRequest)
        .then(handleErrors)

    } else if (unitModel.type === 'etoUnit') {

      const etoUnitValidationRequest: CreateEtoUnitRequest = {
        projectId: unitModel.projectModel?.id ?? null,
        customer: unitModel.projectModel?.customer ?? null,
        productGroup: unitModel.productGroupModel?.code ?? null,
        productKey: unitModel.productKeyModel?.code ?? null,
        consumptionCategory: unitModel.consumptionCategory?.id ?? null,
        type: unitModel.oemType ?? null,
        oemNumber: unitModel.oemNumber ?? null,
        nameEn: unitModel.nameEn ?? null,
        nameRu: unitModel.nameRus ?? null,
        productionName: unitModel.productionName ?? null,
        productLineNotesEn: unitModel.plNotesEn ?? null,
        productLineNotesRu: unitModel.plNotesRus ?? null,
        length: Number(unitModel.length) ?? null,
        widthOrDiameter: Number(unitModel.widthOrDiameter) ?? null,
        height: Number(unitModel.height) ?? null,
        weight: Number(unitModel.weight) ?? null,
        material: unitModel.materialModel?.materialId ?? null,
        unitOfMeasurement: unitModel.unitsOfMeasure?.id ?? null,
        doNotShowEparts: unitModel.doNotShowEparts,
        notForSmNf: unitModel.notForSmNf,
        oemMaterial: unitModel.oemMaterial ?? null,
        comment: unitModel.commentForApproval ?? null,
        subOems: unitModel.subOems.length > 0
          ? unitModel.subOems.map((s) => {
            return {
              mfg: s.mfg ?? '',
              mfgOemNumber: s.mfgOemNumber ?? '',
            }
          })
          : null,
        oversized: unitModel.oversized ?? false,
      }

      await msalFetch()
        .post(`${API_GATEWAY_ADDRESS}/uc/etounitcreaterequests`, result.accessToken, etoUnitValidationRequest)
        .then(handleErrors)
    }
    throw new Error(`unknown unit type: ${unitModel.type}`)
  }
}

const createUnitApi = new CreateUnitApi()

export default createUnitApi
