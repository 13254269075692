import { Box, Portal } from '@mui/material'
import { useSnackbar } from 'notistack'
import {
  type FC,
  useEffect,
  useState,
} from 'react'

import { checkPermission } from 'app/store'
import {
  useAppDispatch,
  useAppSelector,
} from 'app/store/hooks'

import { ConfirmationSingleDialog } from 'widgets/technicalDescriptions/ConfirmationSingleDialog'
import { ReplicationDialog } from 'widgets/technicalDescriptions/Descriptions/replicationDialog/ui/ReplicationDialog'
import InitialsDialog from 'widgets/units/List/InitialsDialog'

import { ShowInitials } from 'features/common'
import { CopyToClipboardButton } from 'features/common/CopyToClipboardButton'
import { DownloadOD } from 'features/common/showOD/ui/DownloadOD'
import { CopyDescriptionButton } from 'features/logistics/CopyDescriptionButton'
import { DescriptionFieldChecksSlice } from 'features/logistics/downloadTechnicalDescriptions'
import { ImportDescriptionButton } from 'features/logistics/ImportDescriptionButton'
import { OpenQaImageGallery } from 'features/logistics/openQAImageGallery'
import { ShowImagesInGallery } from 'features/logistics/showImagesInGallery'
import { NavigateToElement } from 'features/logistics/unitDescription/navigateToElement'
import {
  replicateUnit,
  ReplicationRequest,
  selectReplicateDescriptions,
  selectReplicateDescriptionsRequest,
  selectReplicateDescriptionsStatus,
} from 'features/logistics/unitDescription/replicateDescription/replicateDescriptionSlice'
import { SetPosition } from 'features/logistics/unitDescription/setPosition'
import { type SelectableDescription } from 'features/logistics/units/searchUnts'
import { selectDescriptionsOnSearch } from 'features/logistics/units/searchUnts/model/selectableDescriptions'
import {
  DescriptionUnitImageTypes,
  UploadPicture,
} from 'features/logistics/units/uploadPicture'
import { UploadScheme } from 'features/logistics/units/uploadPicture/ui/UploadScheme'

import {
  ImageList,
  TechnicalCard,
  TechnicalCardApproveState,
  TechnicalDescriptionImageType,
  UnitAdditionalInfo,
  type UnitTechnicalDescription,
  useGetUnitDescriptionsQuery,
} from 'entities/logistics/TechnicalCard'
import { technicalCardCacheUpdate } from 'entities/logistics/TechnicalCard/model/technicalCardsApi'

import { PermissionsLogisticsUnitsReplicate } from 'shared/model/contracts/permissions'

import {DropzoneQAEditedPictures} from '../../../../../features/logistics/uploadQaEditedPicture/ui/DropzoneQAEditedPictures'

interface TechnicalDescriptionsListProps {
  expanded: boolean
}

export const TechnicalDescriptionsList: FC<TechnicalDescriptionsListProps> = ({
  expanded,
}) => {

  const [openConfirm, setOpenConfirm] = useState<boolean>(false)
  const [currentDescription, setCurrentDescription] = useState<SelectableDescription | undefined>(undefined)
  const dispatch = useAppDispatch()
  const markedUnits: SelectableDescription[] | undefined = useAppSelector(selectDescriptionsOnSearch)
  const replicationRequest: ReplicationRequest | undefined = useAppSelector(selectReplicateDescriptionsRequest)
  const replicateDescriptions: SelectableDescription[] | undefined = useAppSelector(selectReplicateDescriptions)
  const replicateDescription = replicateDescriptions[0]
  const markedUnitsId = markedUnits?.map(unit => unit.unitId) || []
  const replicateStatus = useAppSelector(selectReplicateDescriptionsStatus)
  const isLoading = replicateStatus === 'pending'

  const {
    data,
    isSuccess,
    isError,
  } = useGetUnitDescriptionsQuery(markedUnitsId, { skip: !markedUnits })

  const canReplicate = useAppSelector((root) => checkPermission(root, PermissionsLogisticsUnitsReplicate))

  const {
    enqueueSnackbar,
  } = useSnackbar()

  useEffect(() => {
    if (typeof data !== 'undefined') {
      dispatch(DescriptionFieldChecksSlice.actions.removeAll())
      dispatch(DescriptionFieldChecksSlice.actions.addMany(data))
    }
  }, [data])

  const handleImport = async (unitId: number, description: SelectableDescription) => {
    const response = await dispatch(replicateUnit({
      unitId: unitId,
      replicateUnitId: description.unitId,
      replicationRequest: replicationRequest,
    })).unwrap()

    if (response) {
      technicalCardCacheUpdate(dispatch, unitId,
        {
          equipmentType: response.equipmentType,
          principles: response.principles,
          purpose: response.purpose,
          position: response.position,
          manufacturingMethods: response.manufacturingMethods,
          materials: response.materials,
          images: response.images,
          schemes: response.schemes,
        },
      )
    }
  }

  if (isSuccess) {
    return (
      <Box>
        {
          data?.map((description: UnitTechnicalDescription) =>
            <TechnicalCard
              key={description.elementId}
              unitDescription={description}
              expanded={expanded}
              assemblyUnit={!!description?.content?.length}
              unitAdditionalInfoSlot={<UnitAdditionalInfo
                unitId={description.unitId}
                actionsSlot={[
                  <ShowInitials
                    key={`${ShowInitials.displayName}-${description.unitId}`}
                    unit={description}
                  />,
                  <DownloadOD
                    key={`${DownloadOD.displayName}-${description.unitId}`}
                    unitTD={description}
                  />,
                  <OpenQaImageGallery
                    key={`${OpenQaImageGallery.displayName}-${description.unitId}`}
                    unit={description}
                  />,
                ]}
              />}
              topActionSlot={
                [
                  <NavigateToElement
                    key={`${NavigateToElement.displayName}-${description.unitId}`}
                    elementId={description.elementId}
                  />,
                  <CopyToClipboardButton
                    key={`copyid-${description.unitId}`}
                    content={description.elementId || ''}
                    tooltipText={'Копировать в буфер обмена'}
                    sx={{ color: 'white' }}
                    buttonSx={{ marginLeft: 2 }}
                  />,
                  <>
                    {canReplicate &&
                    <CopyDescriptionButton
                      key={`replicate-${description.unitId}`}
                      unitId={description?.unitId || 0}
                      elementId={description?.elementId || ''}
                      sx={{ color: 'white' }}
                    />
                    }
                  </>,
                  <>
                    {canReplicate &&
                                replicateDescriptions &&
                                replicateDescriptions.length > 0 &&
                                replicateDescription.unitId !== description.unitId &&
                                description.state !== TechnicalCardApproveState.Approved &&
                                <ImportDescriptionButton
                                  key={`import-${description.unitId}`}
                                  description={{
                                    elementId: description.elementId,
                                    unitId: description.unitId,
                                  }}
                                  setDescription={setCurrentDescription}
                                  sx={{ color: 'white' }}
                                  setOpen={setOpenConfirm}
                                />}
                  </>,
                ]
              }
              bottomActionSlot={
                [
                  <ImageList
                    key={DescriptionUnitImageTypes.Pictures}
                    unitId={description.unitId}
                    listName={DescriptionUnitImageTypes.Pictures}
                    images={description.images}
                    titleActionsSlot={
                      [
                        <UploadPicture
                          key={`${UploadScheme.displayName}-${description.unitId}`}
                          unitId={description.unitId}
                          isDisabled={description.state === TechnicalCardApproveState.Approved || isLoading}
                        />,
                        <ShowImagesInGallery
                          images={description?.images || []}
                          title={`Images`}
                          unitId={description.unitId}
                          imagetype={TechnicalDescriptionImageType.image}
                        />,
                      ]
                    }
                  />,
                  <ImageList
                    key={DescriptionUnitImageTypes.Schemes}
                    unitId={description.unitId}
                    listName={DescriptionUnitImageTypes.Schemes}
                    images={description.schemes}
                    isDisabled={isLoading}
                    titleActionsSlot={
                      [
                        <UploadScheme
                          key={`${UploadScheme.displayName}-${description.unitId}`}
                          unitId={description.unitId}
                          isDisabled={description.state === TechnicalCardApproveState.Approved || isLoading}
                        />,
                        <ShowImagesInGallery
                          key={`${ShowImagesInGallery.displayName}-${description.unitId}`}
                          images={description?.schemes || []}
                          title={`Schemes`}
                          unitId={description.unitId}
                          imagetype={TechnicalDescriptionImageType.scheme}
                        />,
                      ]
                    }
                  />,
                  <SetPosition
                    key={`${SetPosition.displayName}-${description.unitId}`}
                    unitId={description.unitId}
                    loading={isLoading}
                  />,
                ]}
            />,
          )
        }
        <InitialsDialog/>
        <ReplicationDialog/>
        <ConfirmationSingleDialog
          open={openConfirm}
          description={currentDescription}
          replicateDescription={replicateDescription}
          onConfirm={handleImport}
          setOpen={setOpenConfirm}
        />
        <Portal>
          <DropzoneQAEditedPictures/>
        </Portal>

      </Box>)
  }

  if (isError) {
    enqueueSnackbar('Loading unit descriptions error', {
      variant: 'error',
      preventDuplicate: true,
    })
  }

  return (
    <></>
  )
}
