import {
  AZURE_REDIRECT_ADDRESS,
  REACT_APP_AZURE_API,
  REACT_APP_AZURE_AUTHORITY,
  REACT_APP_AZURE_CLIENT_ID, AZURE_REDIRECT_ALTER_ADDRESS,
} from 'shared/api/api'

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = { scopes: ['User.Read', 'User.ReadBasic.All'] }

export const apiRequest = { scopes: [REACT_APP_AZURE_API ?? ''] }

// // Add the endpoints here for Microsoft Graph API services you'd like to use.
export const graphConfig = {
  graphMeEndpoint: 'https://graph.microsoft.com/v1.0/me',
  graphUsersEndpoint: 'https://graph.microsoft.com/v1.0/users',
}
